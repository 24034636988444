.accordion-wrapper {
    margin: 2rem 0;
    /*border-bottom: 1px dotted #000;*/
}

.accordion-section-open {
    /*background-color: #EEE;*/
    border-top: 1px dotted black;
    border-bottom: 1px dotted black;
    /*padding: 0 10px 5px;*/
}
.accordion-section-closed {
    /*background-color: #EEE;*/
    border-top: 1px dotted black;
    border-bottom: 1px dotted black;
    /*padding: 0 10px 5px;*/
}

.accordion-label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accordion-symbol {
    font-size: 3rem;
    font-weight: bold;
}
.accordion-title {
    margin: 1rem 0 1rem 0;
    font-size: 2rem;
    font-weight: bold;
}
.accordion-content {
    /*background-color: #FFF;*/
    /*border: 1px dotted #000;*/
    /*margin-top: 10px;*/
    padding: 10px 0;
}
/* HEADER STYLES */
.header {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
}
.side-menu {
  display: none;
}

/* LOCATION SELECTOR */
.location-selector {
  display: flex;
}
.location-button {
  height: 50px;
  width: 50px;
  border: 1px dotted rgba(255, 255, 255, 1);
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 1.5rem;
}
.location-button:hover {
  cursor: pointer;
}
.location-button-active {
  color: green;
  background-color: white;
  height: 50px;
  width: 50px;
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 1.5rem;
}
.location-button-active:hover {
  cursor: pointer;
}

/* SIDE MENU TOGGLE */
#side-menu-toggle {
  color: rgba(35, 35, 35, 1);
  cursor: pointer;
  font-size: 3em;
  margin: 1vw 1vw 1vw 0;
  position: relative;
  z-index: 100;
}
#side-menu-toggle:hover {
  background-color: green;
  color: white;
}
#side-menu-open {
  /*background-color: rgba(255, 255, 255, 0.3);*/
  display: block;
  z-index: 100;
}
#side-menu-close {
  display: none;
  z-index: 100;
  /*color: white;*/
}
.nav #side-menu-close {
  color: white;
}

/* SIDE MENU */
#side-menu-links {
  right: -100vw;
}
.header-nav {
  background-color: green;
  /*background-color: rgba(255, 204, 255, 1.0);*/
  /*background-color: rgba(255, 255, 255, 1.0);*/
  color: white;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties  */
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0px;
  transition: all 0.5s ease-in-out;
  width: 100vw;
  z-index: 9;
  box-sizing: border-box;
}

/* MENU LIST STYLES */
.header-nav .nav-links {
  margin: 100px 75px auto auto;
  text-align: right;
  width: 300px;
}
.header-nav .nav-links a {
  color: white;
  border-top: 1px dotted white;
  display: block;
  padding: 1em 0 1em 0;
}
.header-nav .nav-links > a ~ a {
  border-left: 0;
}
.header-nav .nav-links a:last-child {
  border-bottom: 1px dotted white;
  margin-bottom: 1em;
}

#side-menu-links ul {
  display: flex;
  flex-direction: column;
}

/* MEDIA QUERIES */
@media (max-width: 1000px) {
  /* HEADER STYLES */
  .header {
    height: 50px;
  }
  .header-nav > li {
    margin-left: 0.25em;
  }
  .header-nav > li ~ li {
    padding-left: 0.25em;
  }
  .header-nav .nav-links a {
    font-size: 2rem;
  }
  .header-logo {
    left: auto;
  }

  /* MOBILE MENU */
  .side-menu {
    display: block;
  }
  .side-menu-toggle {
    display: block;
  }
  #menulinks {
    display: none;
  }
  #sidemenulinks {
    display: block;
  }
  #login-section {
    display: block;
  }
  .show-mobile-menu {
    display: block;
    float: right;
    padding: 16px 16px 16px 0;
    position: relative;
  }

  .header-nav.active {
    left: 0;
  }
  .header-nav .nav-links {
    margin: 100px 50px auto auto;
    /*position: relative;*/
    text-align: right;
    width: 300px;
  }
  .show-login-menu {
    display: block;
    float: left;
    padding: 16px 0 16px 16px;
    position: relative;
  }
  .login-menu-toggle {
    display: block;
  }
  #login-btn-close {
    display: none;
  }

  /* Mobile menu transition */
  .mobile-menu-check.active ~ .header-nav {
    left: 0;
    height: 100vh;
  }
  .mobile-menu-check.active ~ .show-mobile-menu:after {
    background-color: #fff;
    color: #000;
    content: "\2715";
    left: 0;
    padding: 0 32px 0 0;
    position: absolute;
    width: 100%;
  }
  .content-wrapper.active {
    right: 100vw;
  }

  .header-nav .nav-links li {
    display: block;
    /*margin-top: 1em;*/
    padding: 1em 0 1em 0;
    border-top: 1px dotted #000;
  }
  .header-nav .nav-links > li ~ li {
    border-left: 0;
  }
  .header-nav .nav-links li:last-child {
    margin-bottom: 1em;
    border-bottom: 1px dotted #000;
  }
  .header-nav .nav-links li:first-child a {
    /*color: #fff;*/
    font-weight: bold;
  }
  .header-nav .drop-trigger {
    padding-right: 0;
  }
  .header-nav .drop-menu:after,
  .header-nav .drop-trigger:after {
    display: none;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}
.header-title {
  display: flex;
  align-items: center;
}
.main-link {
  font-weight: normal;
  padding: 0.75rem 0;
}
.location {
  margin: 0 0 0 2rem;
}
.header-menu {
  font-weight: normal;
  padding: 0.75rem 0;
}

/* MEDIA QUERIES */
@media (max-width: 1000px) {
  .header-container {
    font-size: 2rem;
  }
  .main-link {
    padding: 0;
  }
}

/* MEDIA QUERIES */
@media (max-width: 750px) {
}

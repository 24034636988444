.grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    minmax(2em, 2fr) repeat(12, minmax(auto, 1fr))
    minmax(2em, 2fr);
}

.grid-row-header {
  grid-template-rows: 70px 1fr;
}
.grid-row-footer {
  grid-template-rows: 1fr 70px;
}

.grid_1-15 {
  grid-column: 1 / 15;
}
.grid_2-14 {
  grid-column: 2 / 14;
}
.grid_2-5 {
  grid-column: 2 / 5;
}
.grid_5-8 {
  grid-column: 5 / 8;
}

.tile-grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 10px;
}
.tile-grid_1-6 {
  /*grid-column: 1 / 2;*/
}

/* MEDIA QUERIES */
@media (max-width: 1400px) {
  /* HEADER STYLES */
  .grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns:
    minmax(2em, 1fr) repeat(12, minmax(auto, 1fr))
    minmax(2em, 1fr);
  }
  .tile-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    gap: 10px;
  }
}

@media (max-width: 1000px) {
  /* HEADER STYLES */
  .grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns:
    1vw repeat(12, minmax(auto, 1fr))
    1vw;
  }
  .tile-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    gap: 10px;
  }
}

/* SCREEN SIZE CLASSES */
.desktop {
  display: block;
}
.pad {
  display: none;
}
.mobile {
  display: none;
}

/* SIDE MENU STYLES */
#content-wrapper {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

/* OUTLET STYLES */
.main-container {
  margin: 3vw 0;
}
.content-container {
  margin: 2rem 0 2rem 0;
}

/* GENERAL STYLES */
h1 {
  margin: 30px 0 0 0;
  font-size: 1.75rem;
}
p {
  font-size: 1.5rem;
}

/* HEADER AND FOOTER HEMISPHERE SELECTOR STYLES */
#hemisphere-selector {
  background-color: rgba(255, 255, 255, 0);
  appearance: none; /* Remove default styles */
  outline: none; /* Remove default outline */
  border: 1px dotted #000; /* Add a border with 1px width */
  padding: 0 5px; /* Adjust padding as needed */
  font-size: 2rem; /* Adjust font size as needed */
  /* Add any other desired styling */
  border-radius: 0;
}
#hemisphere-selector::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 1rem; /* Adjust font size of the arrow */
  margin-left: 5px; /* Add spacing between text and arrow */
}
select option {
  /* Apply desired styles to the options */
  font-size: 1rem;
}

/* NOW PAGE STYLES */
.hero-text {
  font-size: 4rem;
  font-weight: normal;
  margin: 12rem 0 1rem 0;
}

/* RECORDS INFORMATION */
.records-info-container {
  margin: -1.5rem 0 -1.5rem 0;
}

/* PAGE SECTION STYLES */
.page-hero-container {
  margin: 4rem 0 0 0;
}
.section-flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section-label {
  font-size: 1rem;
  font-weight: bold;
}
.section-title {
  font-size: 8rem;
  /*font-weight: bold;*/
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.section-subtitle {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0 1rem 0;
  color: black;
}
.section-separator {
  /* margin: 2rem 0; */
  height: 0.25rem;
}
table.taxonomy {
  font-size: 1rem;
  border-collapse: separate;
  -webkit-border-horizontal-spacing: 2px;
  -webkit-border-vertical-spacing: 3px;
  text-indent: initial;
}
table.producer {
  margin: 2rem 0 0 0;
  font-size: 2rem;
  border-collapse: separate;
  -webkit-border-horizontal-spacing: 2px;
  -webkit-border-vertical-spacing: 3px;
  text-indent: initial;
}
.section-hero-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vw;
  width: 50vw;
  align-self: center;
}
.section-hero-image-small {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 25vw;
  width: 25vw;
  align-self: center;
}
.hero-list {
  margin: 2rem 0 0 0;
}
.section-grid-container {
  margin: 1rem 0 12rem 0;
}

/* LIST STYLES */
.list {
  line-height: 3rem;
  font-size: 1.5rem;
}

/* link list */
.link-list {
  list-style-type: none;
  margin: 24px 0 50px 0;
}
div.link-list li:before {
}
.list-item {
  display: flex;
  flex-direction: row;
}
.list-item-link {
  margin: 0 0 0 1rem;
}

/* dashed list */
div.dashed-list {
  list-style-type: none;
  padding: 0 0 0 20px;
}
div.dashed-list li:before {
  content: "\2013";
  position: absolute;
  margin-left: -20px;
}
.list-more-link {
  margin: 1rem 0 0 0;
  float: right;
  clear: both;
}
.list-medium {
  font-size: 1.25rem;
  line-height: 2.5rem;
}
.list-small {
  font-size: 1rem;
  line-height: 2rem;
}
.specie-title {
  /*margin: 30px 0 30px 0;*/
  font-size: 4rem;
  font-weight: bold;
}
.specie-details {
  margin: 30px 0 30px 0;
  font-size: 1.5rem;
  line-height: 3rem;
}
.table-key {
  font-style: normal;
  font-weight: normal;
  padding: 0 0.5rem 0 0;
}
.table-value {
  font-style: normal;
}
.section-text {
  margin: 30px 0 30px 0;
}

/* PLANTS STYLES */
.tile-grid-container {
  margin: 1rem 0 1rem;
}
.hero-container {
  position: relative;
  border: 1px dotted black;
  margin: 1rem 0;
}
.image {
  width: 100%;
}
.hero-title {
  /* border: 1px solid black; */
  /* text-align: right; */
  /* background-color: rgba(0, 128, 0, 0.75); */
  color: black;
  font-weight: bold;
  /* padding: 0.5rem; */
  /* width: 50vw; */
  position: absolute;
  /* top: 0;
  left: 0; */
  transform-origin: top left;
  transform: rotate(-90deg) translateX(25%) translateY(50%);
  /* text-align: center; */
}
.tile-container {
  /* border: 1px dotted black; */
  /* margin: 1rem 0; */
  /*width: 96vw;*/
  /*margin: 0 -1vw 3rem -1vw;*/
  /*box-sizing: border-box;*/
}
.tile {
  /*float: left;*/
  position: relative;
  border: 1px dotted black;
  /* margin: 1rem 0; */
  /*width: minmax(auto, 1fr);*/
  /*margin: 1vw;*/
  box-sizing: border-box;
}
.image {
  width: 100%;
}
.tile-title {
  /* border: 1px solid black; */
  /* text-align: right; */
  /* background-color: rgba(0, 128, 0, 0.75); */
  color: black;
  font-weight: bold;
  /* padding: 0.5rem; */
  /* width: 50vw; */
  position: absolute;
  /* top: 0;
  left: 0; */
  transform-origin: top left;
  transform: rotate(-90deg) translateX(25%) translateY(50%);
  /* text-align: center; */
}
.section {
  margin: 4rem 0 0 0;
}

/* RECIPE STYLES */
.instructions-paragraph {
  font-size: 1.25rem;
  padding: 1rem 0 0 0;
}

/* EFFECTS STYLES */
.conditions-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vw;
  width: 30vw;
  align-self: center;
}
.category-link {
  margin: 1rem 0;
}

.tile-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /*height: 20vw;*/
  padding-top: 100%;
  /*width: 30vw;*/
  align-self: center;
}

/* CULTIVAR STYLES */
.subspecie-link {
  font-size: 1.5rem;
  line-height: 3rem;
}
.cultivar-periods {
  width: 24rem;
}
.month {
  width: calc(100% / 12);
  height: 2rem;
  border: 1px solid green;
  color: green;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  font-weight: bold;
}
.active-month {
  color: green;
  background-color: rgba(0, 128, 0, 0.2);
}
.current-month {
  text-align: center;
  color: green;
  /*color: white;*/
  /*background-color: green;*/
}

/* ABOUT STYLES */
.essay-paragraph {
  font-size: 2rem;
  margin: 1rem 0;
}
.essay-image {
  width: 50vw;
  align-self: center;
}

/* MEDIA QUERIES */
@media (max-width: 1000px) {
  /* SCREEN SIZE ACTIVATORS */
  .desktop {
    display: none;
  }
  .pad {
    display: block;
  }
  .mobile {
    display: none;
  }
  .main-container {
    margin: 3vw 3vw;
  }

  /* NOW PAGE STYLES */
  .hero-text {
    font-size: 2rem;
    margin: 6rem 0 1rem 0;
    color: green;
  }

  /* SECTION STYLES */
  .section-label {
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem 0 0 0;
  }
  .section-title {
    font-size: 4rem;
    font-weight: bold;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0 0 0 0;
    width: 94vw;
  }
  table.taxonomy {
    font-size: 1rem;
  }
  table.producer {
    font-size: 2rem;
  }
  .section-hero-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vw;
    width: 60vw;
    align-self: center;
  }

  .specie-title {
    /*margin: 30px 0 30px 0;*/
    font-size: 2rem;
    font-weight: bold;
  }

  /* GALLERY STYLES */
  .section-grid-container {
    margin: 2rem 0 2rem 0;
  }
  .tile-image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 44vw;
    /*width: 40vw;*/
    align-self: center;
  }
}

/* MEDIA QUERIES */
@media (max-width: 750px) {
  /* SCREEN SIZE ACTIVATORS */
  .desktop {
    display: none;
  }
  .pad {
    display: none;
  }
  .mobile {
    display: block;
  }
  .main-container {
    margin: 3vw 3vw;
  }

  /* NOW PAGE STYLES */
  .hero-text {
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
    color: green;
  }

  /* PAGE SECTION STYLES */
  .page-hero-container {
    margin: 2rem 0 0 0;
  }
  .section-title {
    width: 94vw;
  }
  .section-hero-image {
    padding-top: 100%;
    width: 100%;
  }

  .tile-image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 40vw;
    width: 40vw;
    align-self: center;
  }

  .tile {
    /*float: left;*/
    position: relative;
    border: 1px dotted black;
    /* margin: 1rem 0; */
    /*width: minmax(auto, 1fr);*/
    /*margin: 1vw;*/
    box-sizing: border-box;
  }

  /* CULTIVAR STYLES */
  .cultivar-periods {
    width: 100%;
  }
  /* EFFECTS STYLES */
  .conditions-image {
    height: 100%;
    width: 100%;
  }
  /* ABOUT PAGE STYLES */
  .essay-paragraph {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .essay-image {
    width: 100%;
  }
}

.subheader-container {
  display: flex;
  justify-content: left;
  margin: 1em 0 0 0;
}

.subheader-link {
  /* width: 20px; */
  /* float: left; */
  /* width: 30px; */
  padding: 10px;
  margin: 5px;
  border: 1px dotted #000;
}

.subheader-link:hover {
  cursor: pointer;
  float: left;
  /* width: 30px; */
  padding: 10px;
  margin: 5px;
  color: white;
  background-color: #000;
  border: 1px solid #000;
}

.active-subheader-link {
  float: left;
  /* width: 30px; */
  padding: 10px;
  margin: 5px;
  color: white;
  background-color: #000;
  border: 1px solid #000;
}

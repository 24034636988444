/* PAGINATION */
.pagination-links {
  display: flex;
  justify-content: left;
  margin: 2em 0 0 0;
}
.page-link {
  /* width: 20px; */
  float: left;
  /* width: 30px; */
  padding: 10px 8px;
  margin: 5px 4px;
  border: 1px dotted #000;
}

.page-link:hover {
  cursor: pointer;
  float: left;
  /* width: 30px; */
  color: white;
  background-color: #000;
  border: 1px solid #000;
}

.active-page-link {
  float: left;
  /* width: 30px; */
  padding: 10px 8px;
  margin: 5px 4px;
  color: white;
  background-color: #000;
  border: 1px solid #000;
}

.pagination-placeholder {
  margin: 2em 0 0 0;
  height: 48px;
}

.footer {
  margin: 2rem 0 0 0;
  background-color: rgba(0, 128, 0, 0.2);
}
.footer-stamp {
  margin: 5rem 0 0 0;
  font-size: 2rem;
  /*line-height: 3rem;*/
}
.active-hemisphere {
  font-size: 4rem;
  font-weight: bolder;
}
.hemisphere {
  text-decoration: underline;
}
.footer-navigation-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0 0;
  font-size: 1rem;
}
.footer-title {
  display: flex;
  align-items: center;
}
.footer-hero {
  margin: 2rem 0 0 0;
  height: 10rem;
}
.footer-credits-container {
  margin: 1rem 0 5rem 0;
}
.footer-project-title {
  font-size: 2rem;
  font-weight: bold;
}
.footer-project-authors {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 2rem 0;
}
.footer-project-credits {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;
}
.footer-copyright {
  font-size: 1rem;
  font-weight: bold;
}

/* MEDIA QUERIES */
@media (max-width: 1000px) {
  .footer {
    margin: 2rem 0 0 0;
    padding: 0 3vw;
    background-color: rgba(0, 128, 0, 0.2);
  }
  .footer-credits-container {
    margin: 1rem 0 1rem 0;
  }
}

/* MEDIA QUERIES */
@media (max-width: 750px) {
  /* SCREEN SIZE ACTIVATORS */
  .footer {
    margin: 2rem 0 0 0;
    padding: 0 3vw;
    background-color: rgba(0, 128, 0, 0.2);
  }
  .footer-credits-container {
    margin: 1rem 0 1rem 0;
  }
}